import { useState } from "react";
import useApi from "./useApi";

export default function useAuth() {
    const { baseUrl, appSecret } = useApi()

    const getAuth = () => {
        const auth = sessionStorage.getItem('auth') ? JSON.parse(atob(sessionStorage.getItem('auth'))) : null;
        return auth
    }

    const [auth, setAuth] = useState(getAuth());

    const saveAuth = auth => {
        sessionStorage.setItem("auth", btoa(JSON.stringify(auth)));
        setAuth(auth)
    }
    
    function logout() {
        const auth = sessionStorage.getItem('auth') ? JSON.parse(atob(sessionStorage.getItem('auth'))) : null;
        fetch(baseUrl, {
            method: 'POST',
            headers: {
                'app-secret': appSecret,
                'jw-token': auth.jwt
            },
            body: JSON.stringify({method: 'logout'})
        });
        sessionStorage.clear();
        window.location = '/';
    }
    
    return {
        setAuth: saveAuth,
        logout,
        auth,
        appSecret
    }
}