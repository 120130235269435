import { useRef } from "react"

export default function useApi() {
    const base_url = useRef()
    const appSecret = 'mHBjhB&*pbb&bBHbJHB2nmbB@JKB9082sah9ufweo-+-VJY2mnb';
    const auth = sessionStorage.getItem('auth') ? JSON.parse(atob(sessionStorage.getItem('auth'))) : null;

    if (location.hostname === "localhost" ) {
        base_url.current = 'http://localhost:8000/'
    } else {
        base_url.current = 'https://api.iservicesinc.net/'
    }

    const jwt = auth ? auth.jwt : '';
    const fetchData = async (endpoint, method, body) => {
        let result = null
        if (method === "GET") {
            result = await fetch(base_url.current + endpoint, {
                method: method,
                headers: {'app-secret': appSecret, 'jw-token': jwt}
            })
        } else {
            result = await fetch(base_url.current + endpoint, {
                method: method,
                headers: {'app-secret': appSecret, 'jw-token': jwt},
                body: JSON.stringify(body),
            })
        }
        let json_data = await result.json()
        return json_data
    }

    return {baseUrl: base_url.current, appSecret, fetchData}
}
