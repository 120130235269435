import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ProtectRoutes } from '../../hooks/useProtectedRoutes';
import Loading from './components/Loading';

const Header = React.lazy(() => import('./components/Header'));
const Footer = React.lazy(() => import('./components/Footer'));
const HomePage = React.lazy(() => import('./pages/HomePage'));
const AboutPage = React.lazy(() => import('./pages/AboutPage'));
const DevelopersPage = React.lazy(() => import('./pages/DevelopersPage'));
const ContactPage = React.lazy(() => import('./pages/ContactPage'));
const ErrorPage = React.lazy(() => import('./pages/ErrorPage'));
const LoginPage = React.lazy(() => import('../auth/pages/LoginPage'));
const RegisterPage = React.lazy(() => import('../auth/pages/RegisterPage'));
const ProfilePage = React.lazy(() => import('../auth/pages/ProfilePage'));
const OrganizationPage = React.lazy(() => import('../auth/pages/OrganizationPage'));
const VerifyEmailPage = React.lazy(() => import('../auth/pages/VerifyEmailPage'));
const PasswordResetPage = React.lazy(() => import('../auth/pages/PasswordResetPage'));
const ProjectsPage = React.lazy(() => import('../projects/pages/ProjectsPage'));
const TaskPage = React.lazy(() => import('../projects/pages/TaskPage'));
const ServicesPage = React.lazy(() => import('../services/pages/ServicesPage'));

/**
 * Handles application routes
 * @global
 * @name Router
 * @returns {JSX.Element}
 */
export default function Router() {
  return (
    <Suspense fallback={<Loading />}>
      <Header />
      <Routes>
          <Route path='/' element={ <Navigate to='home' exact /> } />
          <Route path='/home' element={ <HomePage /> } />
          <Route path='/about' element={ <AboutPage /> } />
          <Route path='/developers' element={ <DevelopersPage /> } />
          <Route path='/contact' element={ <ContactPage /> } />
          <Route path='/login' element={ <LoginPage /> } />
          <Route path='/register' element={ <RegisterPage /> } />
          <Route element={ <ProtectRoutes /> }>
              <Route path='/account/profile' element={ <ProfilePage /> } />
              <Route path='/account/organization' element={ <OrganizationPage /> } />
              <Route path='/account/organization/:id' element={ <OrganizationPage /> } />
              <Route path='/services' element={ <ServicesPage /> } />
              <Route path='/projects' element={ <ProjectsPage /> } />
              <Route path='/project/:id' element={ <ProjectsPage /> } />
              <Route path='/project/:id/:task_id' element={ <TaskPage /> } />
          </Route>
          <Route path='/account/reset' element={ <PasswordResetPage /> } />
          <Route path='/account/verify' element={ <VerifyEmailPage /> } />
          <Route path='*' element={ <ErrorPage /> } status={404} />
      </Routes>
      <Footer />
    </Suspense>
  )
}