import { Outlet, Navigate } from 'react-router-dom'
import useAuth from './useAuth'
import useApi from './useApi'
import { useEffect, useRef } from 'react'

export const ProtectRoutes = () => {
    const { auth, logout } = useAuth()
    const { fetchData } = useApi()
    const next = btoa(encodeURIComponent(window.location.href))
    const nonce = useRef()

    useEffect(() => {
        if (!nonce.current) {
            nonce.current = true
            if (auth) {
                fetchData('', 'POST',{method: 'verifyToken'})
                .then((json) => {
                    if (json.status !== 200) logout()
                })
                .catch(() => logout())
            }
        }
    }, [auth])

    return auth ? <Outlet/> : <Navigate to={`/login/?next=${next}`} exact />
}