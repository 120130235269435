/**
 * This component renders the loading screen
 * @module Loading
 * @returns {JSX.Element} A React element that renders the loading screen
 */
export default function Loading() {
    return (
        <div className="uk-section uk-section-large uk-flex uk-flex-center uk-flex-middle uk-margin-large" style={{height: '100%', width: '100%'}}>
            <div uk-spinner="ratio: 2"></div>
        </div>
    )
}